import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import {Button, Checkbox, Heading, Link as ChakraLink, Spinner, Text} from "@chakra-ui/react";
import {BorderBox} from "./BorderBox";
import {LabelInfo} from "./LabelInfo";
import {BiPencil} from "react-icons/bi";
import {css} from "@emotion/react";
import {Contract, Position} from "../types";
import {
  baseUrl,
  ContractSignStatus,
  downloadContractPdfByContractId,
  getContractById,
  getContractSignStatus, getPositionById, invalidateContract, remindDocument,
  signDocument
} from "../net-utils";
import {useNavigate, useParams} from "react-router-dom";
import {DefaultSpinner} from "./DefaultSpinner";
import {Input} from "@chakra-ui/input";
import {CheckIcon, CloseIcon, DownloadIcon, ExternalLinkIcon} from "@chakra-ui/icons";
import {FaFileInvoice, FaHandshake} from "react-icons/fa";
import {useHookstate} from "@hookstate/core";
import {globalUser} from "../App";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import {formatDate, getUserJwt, getUsername} from "../heka-utils";
import AlertDisplay from "./AlertDisplay";
import {GoButton} from "./GoButton";

function SignContractForm(props: { signed: boolean | null, ssn: string, contractId: string, refresh: () => void, disabled: boolean }) {
  const phoneState = useHookstate('')
  const user = useHookstate(globalUser)
  const isSigning = useHookstate(false)
  const errorState = useHookstate<string | null>(null)
  const [useApp, setUseApp] = useState(false);
  useEffect(() => {
    const phoneNumber = user.get()?.user?.preferences?.phoneNumbers?.find(p => p.country === 'Iceland')
    phoneState.set(phoneNumber?.phoneNumber ?? '')
  }, [user]);
  const disabled = props.signed || isSigning.get() || props.signed === null || props.disabled;
  return <div>
    <b>Símanúmer</b>
    {errorState.get() && <Text color="red.500">{errorState.get()}</Text>}
    <div css={css`display: flex; gap: 4px;`}>
      <div>
        <Input placeholder={"Símanúmer"} type={"text"} value={phoneState?.get()!!}
          isDisabled={disabled}
          onChange={e => phoneState.set(e.target.value)}/>
      </div>
      <div>
        <Button leftIcon={
          isSigning.get() ? <Spinner/> : <BiPencil/>
        } colorScheme={"green"} isDisabled={disabled}
          onClick={async () => {
            isSigning.set(true)
            try {
              await signDocument(props.contractId, phoneState.get(), useApp);
            } catch (e) {
              if (e instanceof Error) {
                errorState.set(e.message)
              } else errorState.set("Óþekkt villa")
            }
            isSigning.set(false)
            props.refresh()
          }}>
          Undirrita
        </Button>
      </div>
    </div>
    {isSigning.get() && <Text>Undirritun í gangi, kíktu á símann þinn</Text>}
    <Text fontSize={'xs'}>Rafræn undirritun fer í gegnum Signet og notar {!useApp ? 'rafræn skilríki í gegnum GSM síma.' : 'Auðkennisapp í stað GSM rafræn skilríki.'}</Text>
    <Checkbox onChange={(e) => setUseApp(e.target.checked)} isChecked={useApp}>
      Nota Auðkennisapp í stað GSM rafræn skilríki
    </Checkbox>
  </div>
}

function ContractSigner(props: {
  name: string,
  subtitle: string,
  ssn: string,
  email: string,
  showSignContractForm: boolean,
  signed: boolean | null,
  contractId: string,
  refresh: () => void,
  disabled: boolean
}) {
  return <div css={css`display: flex; flex-direction: column; gap: 4px;`}>
    <div css={css`display: flex; gap: 8px; align-items: center`}>
      <Heading fontSize={"2xl"}>{props.name}</Heading>
      <div css={css`display: flex; gap: 2px`}>
        <BiPencil/>
        {props.signed === null ? <Spinner/> : props.signed ? <CheckIcon color={'green.600'}/> : <CloseIcon color={'red.600'}/>}
      </div>
    </div>
    <Text color={"gray.600"} fontStyle={"italic"}>{props.subtitle}</Text>
    <LabelInfo label={"Kennitala"} value={props.ssn}/>
    <LabelInfo label={"Netfang"} value={props.email}/>
    <LabelInfo label={"Undirritaður"} value={props.signed === null ? "Augnablik..." : props.signed ? "Já" : "Nei"}/>
    {props.showSignContractForm && <SignContractForm signed={props.signed} ssn={props.ssn} contractId={props.contractId} refresh={props.refresh} disabled={props.disabled}/>}
  </div>;
}

const isContractRejected = (contractSignStatus: ContractSignStatus) => contractSignStatus.contractStatus === 'Rejected'
  || contractSignStatus.contractStatus === 'Deleted' || contractSignStatus.contractStatus === 'Cancelled';

const ContractCreatedPage = () => {
  const [contract, setContract] = useState<Contract | null>(null);
  const [contractSignStatus, setContractSignStatus] = useState(null as ContractSignStatus | null);
  const params = useParams();
  const contractId = params.contractId;
  const [position, setPosition] = useState(null as Position | null)
  const remindError = useHookstate(null as string | null);
  const [isReminded, setIsReminded] = useState(false);
  const navigate = useNavigate();

  if (!contractId) {
    throw new Error('contractId is required');
  }
  const refresh = () => {
    (async () => {
      const contract = await getContractById(contractId);
      setContract(contract);
    })();
    (async () => {
      const contractSignStatus = await getContractSignStatus(contractId);
      setContractSignStatus(contractSignStatus);
    })()
  };
  useEffect(() => {
    refresh()
  }, [contractId]);
  useEffect(() => {
    (async () => {
      if (!contract) return;
      const position = await getPositionById(contract.positionId)
      setPosition(position)
    })()
  }, [contract])
  if (!contract) {
    return <DefaultSpinner/>;
  }

  const markdown = ReactMarkdown({
    children: contract.markdown,
    components: ChakraUIRenderer()
  });

  const isInstitutionSigner = getUsername() !== contract.employeeSigner.username;
  const institutionSignerComponent = <ContractSigner ssn={contract.institutionSigner.ssn}
    email={contract.institutionSigner.email}
    name={contract.institutionSigner.name.length ? contract.institutionSigner.name : "Undirritandi stofnunar"}
    subtitle={"Undirritandi stofnunar"}
    signed={contractSignStatus?.institutionSigned ?? null}
    showSignContractForm={isInstitutionSigner}
    contractId={contractId}
    refresh={refresh}
    disabled={position?.cancelled ?? true}
  />;
  const employeeSignerComponent = <ContractSigner ssn={contract.employeeSigner.ssn}
    email={contract.employeeSigner.email}
    name={contract.employeeSigner.name}
    subtitle={"Starfsmaður"}
    signed={contractSignStatus?.employeeSigned ?? null}
    showSignContractForm={!isInstitutionSigner}
    contractId={contractId}
    refresh={refresh}
    disabled={position?.cancelled ?? true}
  />;
  const rejected = contractSignStatus && isContractRejected(contractSignStatus);
  return (
    <BasePage>
      <Heading>Samningur</Heading>
      {remindError && <AlertDisplay error={remindError}/>}
      {position?.cancelled && <AlertDisplay error={`Hætt hefur verið við stöðu (skýring: "${position.cancelledReason}", dagsetning: "${position.cancelledAtDate ? formatDate(position.cancelledAtDate) : ''}")`}/>}
      {contractSignStatus && rejected &&
          <AlertDisplay error={`Samningur hafnaður af ${contractSignStatus.declinedBy ?? 'aðila'}: ${contractSignStatus?.reason ?? 'Engin skýring'}`}/>}
      {contractSignStatus && rejected && <GoButton isDisabled={!contract} onClick={async () => {
        await invalidateContract(contract?.positionRequestId!!);
        const contractId = contract?.positionRequestId;
        if (!contractId) throw new Error('contractId is required');
        navigate(`/contracts/${contractId}`)
      }} submitError={remindError}>Endurgera samning</GoButton>}
      <BorderBox css={css`display: flex;
        gap: 4px; flex-wrap: wrap`}>
        <ChakraLink target={'_blank'} href={(() => {
          if (process.env.NODE_ENV === 'development') {
            return `https://prufa.signet.is/Authed/Sign/${contract.signetDocumentData.DocIDs[0]}`
          }
          return `https://signet.is/Authed/Sign/${contract.signetDocumentData.DocIDs[0]}`;
        })()}>
          Skoða samning á Signet <ExternalLinkIcon mx='2px'/>
        </ChakraLink>
        -
        <ChakraLink href={`/positions/${contract.positionId}`} target={'_blank'}>Skoða stöðu</ChakraLink>
        -
        <ChakraLink href={`/users/${contract.employeeSigner.username}`} target={'_blank'}>Skoða prófíl verktaka</ChakraLink>
      </BorderBox>
      {
        contractSignStatus?.employeeSigned === false &&
        <GoButton onClick={async () => {
          await remindDocument(contractId);
          setIsReminded(true);
        }} submitError={remindError} isDisabled={isReminded}>
            Senda áminningu
        </GoButton>
      }
      <BorderBox css={css`display: flex;
        justify-content: space-between; flex-wrap: wrap`}>
        {isInstitutionSigner ? institutionSignerComponent : employeeSignerComponent}
        <FaHandshake fontSize={60} opacity={0.8} css={css`margin-top: 50px`}/>
        {isInstitutionSigner ? employeeSignerComponent : institutionSignerComponent}
      </BorderBox>
      <BorderBox>
        <embed
          src={`${baseUrl}/api/v1/contracts/${contractId}/download?jwt=${getUserJwt()}`}
          type="application/pdf"
          height="1000px"
          width="100%"
        ></embed>
      </BorderBox>
      <BorderBox>
        <div css={css`display: flex;
          gap: 8px`}>
          <Button leftIcon={<DownloadIcon/>} onClick={async () => {
            await downloadContractPdfByContractId(contractId);
          }}>Sækja PDF</Button>
          {/*{contract.employeeSigner.username === getUsername() && <Button colorScheme={'blue'}*/}
          {/*    leftIcon={<FaFileInvoice/>}*/}
          {/*    onClick={() => navigate(`/positions/${contract!!.positionId}/invoice`)}>*/}
          {/*  {*/}
          {/*    position?.invoiceSent ? 'Skoða reikning' : 'Senda reikning'*/}
          {/*  }*/}
          {/*</Button>}*/}
        </div>
        <div>
          {markdown}
          {/*<p><b>Samningsnúmer:</b> {contract.contractId}</p> ALREADY IN CONTRACT*/}
        </div>
      </BorderBox>
    </BasePage>
  );
};

export default ContractCreatedPage;
